import type { InputHTMLAttributes, LabelHTMLAttributes } from 'react'
import { Checkbox } from '@/components/ui/checkbox'
import { FormField, FormItem, FormMessage } from '@/components/ui/form'
import { Label } from '@/components/ui/label'
import { cn } from '@/lib/utils'
import type { CheckedState } from '@radix-ui/react-checkbox'
import type { FieldValues, Path } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'

import { camelCaseToSpacedTitleCase, classNames } from '@acme/shared'

interface MdCheckboxProps {
  label?: string
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>
  inputProps?: InputHTMLAttributes<HTMLInputElement>
  containerClassName?: string
  name: string
  onChange: (checked: CheckedState) => void
  value: boolean
  required?: boolean
}

const MdCheckbox = ({
  name,
  label,
  labelProps,
  inputProps,
  containerClassName,
  onChange,
  value,
  required,
}: MdCheckboxProps) => {
  const { className: labelClassName, ...labelRest } = labelProps ?? {}
  const { className: inputClassName } = inputProps ?? {}
  const usedLabel = label ?? camelCaseToSpacedTitleCase(name ?? '')

  return (
    <div
      className={cn(
        'mt-1 grid w-full items-center gap-1.5',
        containerClassName,
      )}
    >
      <Label htmlFor='name' className={labelClassName} {...labelRest}>
        {usedLabel}
        {required ? <span className='text-error ml-1'>*</span> : null}
      </Label>
      <Checkbox
        name={name}
        checked={value}
        onCheckedChange={onChange}
        className={classNames(inputClassName, 'rounded-sm border border-black')}
      />
    </div>
  )
}

const ControlledMdCheckbox = <T extends FieldValues>({
  name,
  ...props
}: {
  name: Path<T>
} & Omit<MdCheckboxProps, 'onChange' | 'value'>) => {
  const { control } = useFormContext()
  return (
    <FormField
      name={name}
      control={control}
      render={({ field: { value, onChange, ...fieldRest } }) => {
        return (
          <FormItem>
            <MdCheckbox
              {...props}
              {...fieldRest}
              value={value}
              onChange={onChange}
            />
            <FormMessage />
          </FormItem>
        )
      }}
    />
  )
}

export { ControlledMdCheckbox, MdCheckbox }
export type { MdCheckboxProps }
